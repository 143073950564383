<template>
    <div v-show="userInfo.exist&&userInfo.userId!='getprice'" style="margin: 10px;">
        <iframe :src="dowloadUrl" style="width:0;height:0;display:none;" frameborder="0"></iframe>
        <Card>
            <template #title>
                <span style="margin: 0 auto;">文库自助下载</span>
            </template>
            <template #content>
                
                <h5 v-show="userInfo.isAdmin">VIP下载次数：{{userInfo.vip}}&nbsp;&nbsp;&nbsp;&nbsp;已使用次数：{{userInfo.vipUsed}}&nbsp;&nbsp;&nbsp;&nbsp;付费文档点数：{{userInfo.sale}}&nbsp;&nbsp;&nbsp;&nbsp;已使用点数：{{userInfo.saleUsed}}</h5>
                <h5 v-show="!userInfo.isAdmin">购买点数：{{userInfo.sale}}&nbsp;&nbsp;&nbsp;&nbsp;已使用点数：{{userInfo.saleUsed}}</h5>
                <InputText v-show="false" type="text" style="width: 300px;" v-model="userInfo.userId" placeholder="请输入用户ID"/>
                <div style="align-items:center;display: flex; margin: 1rem 0;">
                    请在下方输入需要下载的文档的网址：
                    <Button v-show="userInfo.isAdmin" label="粘贴网址" @click="setFileUrl"/>
                    <Button class="ml-2" :loading="getPriceBtn.loading" :label="getPriceBtn.label" @click="getPrice"/>
                </div>
                <Textarea v-model="fileUrl" rows="5" style="width: 100%;"/>
                <div v-show="userInfo.isAdmin" style="align-items: center;display: flex; margin-top: 1rem;">
                    <Checkbox v-model="isSendEmail" :binary="true" class="mr-1"/>同时发送到邮箱
                </div>
                
        <div v-show="userInfo.isAdmin" class="p-inputgroup mt-3" style="width: 300px;">
            <InputText placeholder="请输入邮箱地址" v-model="email" :disabled="!isSendEmail" type="text" />
            <Button v-show="userInfo.isAdmin" label="粘贴邮箱" @click="setEmail"/>
        </div>
                <Tag v-show="tag.success" class="mt-2" icon="pi pi-check" severity="success" value="邮件发送成功"></Tag>
                <Tag v-show="tag.fail" class="mt-2" icon="pi pi-times" severity="danger" value="邮件发送失败"></Tag>
            </template>
            <template #footer>
                
                <Button :loading="downLoadBtn.loading" :label="downLoadBtn.label" :disabled="downLoadBtn.disabled" @click="downLoad" />
                <Button  v-show="userInfo.isAdmin" class="ml-2"  label="全部清空" :disabled="downLoadBtn.disabled" @click="clearAll" />
                
            </template>
        </Card>
        <Card class="mt-3">
            <template #title>
                <span style="margin: 0 auto;">下载记录</span>
                <InputText class="ml-2" placeholder="请输入邮箱地址" v-model="email2" type="text" />
            </template>
            <template #content>
            <DataTable :value="fileList" showGridlines dataKey="downLoadId" responsiveLayout="scroll">
            <Column header="操作">
                <template #body="{data}">
                    <Button :loading="data.loading"  :label="data.downLoadBtn" @click="downLoadById(data.downloadId)" />
                    <Button :loading="data.sendEmailloading"  class="ml-2"  :label="data.sendEmailBtn" @click="sendEmailById(data.downloadId)" />
                </template>
                
            </Column>
            <Column field="fileName" header="文件名"></Column>
            <Column field="fileSize" header="文件大小"></Column>
            <Column field="createDate" header="下载时间"></Column>
            </DataTable>
        </template>
        </Card>
        <Card v-show="userInfo.isAdmin" class="mt-3">
            <template #title>
                <span style="margin: 0 auto;">原创力文档</span>
            </template>
            <template #content>
                <h6>请在下方输入需要下载的文档的地址：</h6>
                <Textarea v-model="book118Url" rows="5" style="width: 100%;"/>
                <ProgressBar v-show="bar.display" :value="bar.value" />
                
            </template>
            <template #footer>
                <Button :label="book118Btn.label" :loading="book118Btn.loading" @click="getDoc" />
            </template>
        </Card>
        
    </div>
    <div v-show="userInfo.exist&&userInfo.userId=='getprice'" style="margin: 10px;">
        <Card>
            <template #title>
                <span style="margin: 0 auto;">文库自助下载价格查询</span>
            </template>
            <template #content>
                <div style="align-items:center;display: flex; margin: 1rem 0;">
                    请在下方输入需要下载的文档的网址：
                </div>
                <Textarea v-model="fileUrl" rows="5" style="width: 100%;"/>
            </template>
            <template #footer>
                <Button :loading="getPriceBtn.loading" :label="getPriceBtn.label" @click="getPrice"/>
            </template>
        </Card>
    </div>
</template>

<script>
import jsPDF from 'jspdf';
export default {
    data(){
        return{
            userInfo:{
                userId:'',
                vip:0,
                vipUsed:0,
                sale:0,
                saleUsed:0,
                isAdmin:false,
                exist:false
            },
            tag:{
                success:false,
                sevefailrity:false
            },
            bar:{
                value:0,
                display:false
            },
            book118Btn:{
                label:'下载文档',
                loading:false
            },
            book118Url:'',
            fileUrl:'',
            email:'',
            email2:'',
            isSendEmail:false,
            
            attachment:{},
            sendEmailBtn:{
                disabled:false,
                label:'发送邮件',
                loading:false
            },
            downLoadBtn:{
                disabled:false,
                label:'下载文件',
                loading:false
            },
            getPriceBtn:{
                label:'查看价格',
                loading:false
            },
            dowloadUrl:'',
            fileList:[],
        }
    },
    mounted(){
        this.userInfo.userId = this.$route.query.userId;
        this.getUserInfo();
        this.getFileList();
    },

    methods: {
        setFileUrl(){
            navigator.clipboard.readText().then((v) => {
                this.fileUrl= v; 
            });
        },
        setEmail(){
            navigator.clipboard.readText().then((v) => {
                this.email= v; 
            });
        },
        clearAll(){
            this.email='';
            this.fileUrl='';
            this.tag.success=false;
            this.tag.fail=false;
        },
        downLoad(){
            this.tag.success=false;
            this.tag.fail=false;
            if(this.userInfo.userId.trim()==''){
                alert('用户不存在！');
                return;
            }
            if(!this.fileUrl.trim()){
                alert('请输入需要下载的文档网址!');
                return;
            }

            let newFileUrl=this.fileUrl;
            if(newFileUrl.search("6viyij.smartapps.baidu.com")>=0){
                newFileUrl=decodeURIComponent(this.fileUrl);
                let reFileUrl = /(?<=docId=)([0-9]|[a-z])+(?=&)/;
                let u=reFileUrl.exec(newFileUrl);
                if(u.length>=1){
                    newFileUrl="https://wenku.baidu.com/view/"+u[0];
                }else{
                    alert('请输入正确的文档网址!请咨询客服可以下载哪些平台文档，然后重试！');
                    return;
                }
                console.log(newFileUrl);
            }
            let urlRe=/(https|http):\/\/(wenku|wk).baidu.com\/(view|tfview|aggs)\/([0-9]|[a-z])+/;
            if(!urlRe.test(newFileUrl)){
                alert('请输入正确的文档网址!请咨询客服可以下载哪些平台文档，然后重试！');
                return;
            }    
            let email = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
            if(this.isSendEmail&&(!this.email||!email.test(this.email.trim()))){
                
                alert('请输入正确的邮箱!');
                return;
            }
            let url='/service/getFileData.php?userId='+this.userInfo.userId+'&wenkuUrl='+newFileUrl;
            if(this.isSendEmail){
                url=url+'&email='+this.email;
            }
            this.changeLoading('downLoadBtn',true);
            fetch(url).then(res=>res.json()).then(data=>{
                this.changeLoading('downLoadBtn',false);
                if(data.status==true){
                    if(this.isSendEmail){
                        if(data.sendEmailResult=='success')
                            this.tag.success=true;
                        else{
                            this.tag.fail=true;
                        }
                    }
                    this.dowloadUrl='';
                    this.dowloadUrl=data.url;
                    this.getUserInfo();
                    this.getFileList();
                }else{
                    alert(data.message);
                    if(this.isSendEmail)
                    this.tag.fail=true;
                }
            }).catch(error=>{
                alert('意外的错误，请联系卖家处理！');
                console.log(error);
                this.changeLoading('downLoadBtn',false);
                if(this.isSendEmail)
                this.tag.fail=true;
            })
        },
        downLoadById(downLoadId){
            this.changeLoading(downLoadId,true);
            let url='/service/getFileDataByDownLoadId.php?userId='+this.userInfo.userId+'&downLoadId='+downLoadId;
            fetch(url).then(res=>res.json()).then(data=>{
                if(data.status==true){
                    this.dowloadUrl='';
                    this.dowloadUrl=data.url;
                }else{
                    alert(data.message);
                }
                this.changeLoading(downLoadId,false);
            }).catch(error=>{
                alert('意外的错误，请联系卖家处理！');
                console.log(error);
                this.changeLoading(downLoadId,false);
            })
        },
        sendEmailById(downLoadId){
           
            let email = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
            if(!this.email2||!email.test(this.email2.trim())){
                
                alert('请输入正确的邮箱!');
                return;
            }
            this.changeLoading(downLoadId,true,true);
            let url='/service/sendEmailByDownLoadId.php?userId='+this.userInfo.userId+'&downLoadId='+downLoadId+'&email='+this.email2;
            fetch(url).then(res=>res.json()).then(data=>{
                alert(data.message);
                this.changeLoading(downLoadId,false,true);
            }).catch(error=>{
                alert('意外的错误，请联系卖家处理！');
                console.log(error);
                this.changeLoading(downLoadId,false,true);
            })
        },

        autoSendEmail(fileSize,fileUrl=''){
            fileUrl=this.fileUrl;
            let fileName=this.getFileName(fileUrl);
            this.downLoadBtn.label='正在发送邮件';
            // if(fileSize>5){
                fetch('https://mail.mizongkeji.xyz/sendEmail?fileUrl='+fileUrl+'&email='+this.email+'&fileName='+fileName).then(res=>res.text()).catch(data=>{
                    this.changeLoading('downLoadBtn',false);
                    if(data=='success'){
                        alert('邮件发送成功!');
                    }
                    else{
                        alert('邮件发送失败!');
                    }
                }).catch(error=>{
                    console.log(error);
                    this.changeLoading('downLoadBtn',false);
                });
            // }else{
            //     let attachment={
            //         name : fileName,
            //         path : fileUrl
            //     };
            //     let _this=this;
            //     let callback=function(data){
            //         _this.changeLoading('downLoadBtn',false);
            //     if(data=='OK'){
            //         alert('邮件发送成功!');
            //     }
            //     else{
            //         alert('邮件发送成功!');
            //     }
            // };
            // let catchcallback=function(){
            //     _this.changeLoading('downLoadBtn',false);
            // };
            //     this.sendEmailBySmtpJS(attachment,callback,catchcallback);
            // }
        },
        manualSendEmail(){
            this.changeLoading('sendEmailBtn',true);
            let _this=this;
            let callback=function(data){
                _this.changeLoading('sendEmailBtn',false);
                if(data=='OK'){
                    alert('邮件发送成功!');
                }
                else{
                    alert('邮件发送失败!'+data);
                }
            };
            let catchcallback=function(){
                _this.changeLoading('sendEmailBtn',false);
            };
            this.sendEmailBySmtpJS(this.attachment,callback,catchcallback);
        },
        getFileName(url){
            let fileUrl=decodeURIComponent(decodeURIComponent(url));
            let pattern = /(?<=filename=").+(?=";)/;
            let res= pattern.exec(fileUrl);
            return res[0];
        },
        getPrice(){
            if(this.userInfo.userId.trim()==''){
                alert('用户不存在！');
                return;
            }

            if(!this.fileUrl.trim()){
                alert('请输入需要下载的文档网址!');
                return;
            }
            this.getPriceBtn.label='正在获取';
            this.getPriceBtn.loading=true;
            let url='/service/getPrice.php?userId='+this.userInfo.userId+'&wenkuUrl='+this.fileUrl;
            fetch(url).then(res=>res.json()).then(data=>{
                alert(data.message);
            }).finally(()=>{
                this.getPriceBtn.label='查看价格';
                this.getPriceBtn.loading=false;
            });
        },
        getUserInfo(){
            if(!this.userInfo.userId||this.userInfo.userId.trim()==''){
                return;
            }
            let url='/service/getUserInfo.php?userId='+this.userInfo.userId;
            fetch(url).then(res=>res.json()).then(data=>{
                if(data.result==="success"){
                    this.userInfo.exist=true;
                    this.userInfo.vip=data.user.vip;
                    this.userInfo.vipUsed=data.user.vipUsed;
                    this.userInfo.sale=data.user.sale;
                    this.userInfo.saleUsed=data.user.saleUsed;
                    this.userInfo.isAdmin=data.user.admin==="1"?true:false;
                    this.isSendEmail=data.user.admin==="1"?true:false;
                }
            });
        },
        getFileList(){
            if(this.userInfo.userId.trim()==''){
                return;
            }
            let url='/service/getFileList.php?userId='+this.userInfo.userId;
            fetch(url).then(res=>res.json()).then(data=>{
                if(data.result==="success"){
                    this.fileList=data.fileList;
                    this.fileList.forEach(file=>{
                        file.downLoadBtn='重新下载';
                        file.sendEmailBtn='发送邮件';
                    });
                    //console.log(this.fileList);
                }else{
                    alert('error');
                }
            });
        },
        async selectFile(event){
            this.sendEmailBtn.disabled=true;
            let base64File = await this.readFileAsDataURL(event.files[0]);
            this.attachment={
                name : event.files[0].name,
                data:base64File
            };
            this.sendEmailBtn.disabled=false;
        },

        readFileAsDataURL(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    resolve(event.target.result);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            });
        },
        changeLoading(btn,loadingState,email=false){
            if(btn==='downLoadBtn'){
                if(loadingState===true){
                    this.downLoadBtn.loading=true;
                    this.downLoadBtn.label='正在下载文件';
                }
                if(loadingState===false){
                    this.downLoadBtn.loading=false;
                    this.downLoadBtn.label='下载文件';
                }
                return;
            }
            if(btn==='sendEmailBtn'){
                if(loadingState===true){
                    this.sendEmailBtn.loading=true;
                    this.sendEmailBtn.label='正在发送邮件';
                }
                if(loadingState===false){
                    this.sendEmailBtn.loading=false;
                    this.sendEmailBtn.label='发送邮件';
                }
                return;
            }
            this.fileList.forEach(file=>{
                if(file.downloadId==btn){
                    if(!email){
                        if(loadingState===true){
                            file.loading=true;
                            file.downLoadBtn='正在下载';
                        }
                        if(loadingState===false){
                            file.loading=false;
                            file.downLoadBtn='重新下载';
                        }
                    }else{
                        if(loadingState===true){
                            file.sendEmailloading=true;
                            this.sendEmailBtn='正在发送邮件';
                        }
                        if(loadingState===false){
                            file.sendEmailloading=false;
                            this.sendEmailBtn='发送邮件';
                        }
                    }
                    
                }
            });
        },
        getDoc(){
            this.bar.display=false;
            this.bar.value=0;
            let pattern = /(?<=max.book118.com\/html\/).*?(?=.shtm)/;
            if(!pattern.test(this.book118Url)){
                alert('请输入正确的原创力文档网址!');
                return;
            }
            this.book118Btn.label="正在下载文档";
            this.book118Btn.loading=true;
            let id=pattern.exec(this.book118Url)[0];
            let url='https://mail.mizongkeji.xyz/book118?id='+id;
            fetch(url).then(res=>res.json()).then(obj=>{
                if(obj.result=='success'){
                    this.convertImageToPDF(obj);
                }
                else{
                    alert(obj.message);
                    this.book118Btn.label="下载文档";
                    this.book118Btn.loading=false;
                }
            }).catch(error=>{
                console.log(error);
                this.book118Btn.label="下载文档";
                this.book118Btn.loading=false;
            });
        },
       async convertImageToPDF(obj) {
        this.book118Btn.label="正在获取图片";
           let total=obj.total;
            let data=obj.data;
            let op={unit: 'px',hotfixes:["px_scaling"]};
            const doc = new jsPDF(op);
            doc.deletePage(1);
            this.bar.display=true;
            for (let i=1;i<=total;i++) {
                let imageUrl=data[i];
                let img=await this.loadImage(imageUrl);
                console.log(img);
                let imgRatio = img.width / img.height;
                if(imgRatio<1){
                    doc.addPage([img.width,img.height]);
                }else{
                    doc.addPage([img.width,img.height],'l');
                }
                doc.addImage(img.data, 'PNG', 0, 0, img.width,img.height); // 添加图像
                this.bar.value=Math.floor(i/total*100);
            }
            let title=obj.title.split(".")[0];
            doc.save(title+'.pdf'); // 保存PDF文件
            this.book118Btn.label="下载文档";
            this.book118Btn.loading=false;
    },
    async loadImage(url) {
      return new Promise(resolve => {
        const img = new Image();
        img.onload = () => resolve({ data:img,width:img.width,height:img.height,loaded: true });
        img.onerror = () => resolve({});
        img.src = url;
      });
    },
    urlencode (str) {  
        str = (str + '').toString();   
        return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+'); 
    }
}
}
</script>